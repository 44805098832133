<template>
    <div class="flex flex-col gap-6 items-center">
        <div
            class="flex flex-col gap-x-8 rounded-md bg-slate-800 p-10 md:flex-row"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style>

</style>